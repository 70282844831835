import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";

export default function DataExtractionLlm({
  onClose,
  nodeForm,
  onSubmit,
  onBack
}) {
  const { watch, setValue } = nodeForm.form;
  const {data} = watch();
  const dataForm = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });
  const dataExtractions = data?.data_extractions || [];
  
  return (
    <>
      <ModalBody>
        <Stack spacing={2}>
          <form
            onSubmit={dataForm.handleSubmit((values) => {
              const dataExtractionFields = [
                ...dataExtractions,
                { ...values, id: `${Date.now()}` },
              ];
              setValue("data.data_extractions", dataExtractionFields);
              localStorage.setItem(
                "dataExtractionFields",
                JSON.stringify(dataExtractionFields)
              );
              dataForm.reset();
            })}
          >
            <Stack spacing={2}>
              <Alert status="info">
                <AlertIcon />
                Extract data from the conversation till this step and store them
                as variables. The variables can later be used in the Flow for
                further processing or invoking Tools.
              </Alert>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input {...dataForm.register("name")} />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea {...dataForm.register("description")} />
              </FormControl>
              <Button type="submit">Add</Button>
            </Stack>
          </form>

          {dataExtractions?.length && (
            <Text fontWeight={"semi-bold"}>Data extraction fields</Text>
          )}
          <Flex gap={2} justifyContent={"flex-start"} alignItems={"center"}>
            {dataExtractions.map((dataExtraction) => (
              <Tag
                key={dataExtraction.id}
                variant="subtle"
                size={"lg"}
                colorScheme="blue"
              >
                <TagLeftIcon
                  cursor={"pointer"}
                  onClick={() => {
                    const filtered = dataExtractions.filter(
                      (data) => data.id !== dataExtraction.id
                    );
                    setValue(
                      "data.data_extractions",
                      dataExtractions.filter(
                        (data) => data.id !== dataExtraction.id
                      )
                    );
                  }}
                  boxSize="12px"
                  as={MdDeleteOutline}
                />
                <TagLabel>{dataExtraction.name}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button onClick={onSubmit} colorScheme="yellow">
          Next
        </Button>
      </ModalFooter>
    </>
  );
}
