import { useSteps } from "@chakra-ui/react";
import NodeTypeSelectForm from "../NodeTypeSelectForm";
import { LiaToolsSolid } from "react-icons/lia";
import { MdOutlineWebhook } from "react-icons/md";
import BasicLlmForm from "../LLMResponse/BasicLlmForm";
import ToolSelectLlm from "../LLMResponse/ToolSelectLlm";
import DataExtractionLlm from "../LLMResponse/DataExtractionLlm";
import GoalLlm from "../LLMResponse/GoalLlm";
import BasicToolFunctionForm from "../ToolFunction/ToolFunctionForm";
import ToolFunctionDataExtraction from "../ToolFunction/ToolFunctionDataExtraction";
import BasicToolForm from "../ToolFunction/BasicToolForm";
import { BiConversation } from "react-icons/bi";

export default function useStepper({ nodeForm, onClose, operations, nodes, edges, flowName }) {
  const { data = {}, id } = nodeForm.form.watch();

  const nodeTypeOptions = [
    {
      label: "Conversation",
      value: "conversation",
      Icon: BiConversation,
      steps: [
        {
          title: "Basic",
          description: "Basic details",
          Children: BasicLlmForm,
          onSubmit: (values) => {
            stepper.goToNext();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
        {
          title: "Tool",
          description: "Select tool",
          Children: ToolSelectLlm,
          onSubmit: (values) => {
            stepper.goToNext();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
        {
          title: "Data",
          description: "Data extraction",
          Children: DataExtractionLlm,
          onSubmit: () => {
            stepper.goToNext();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
        {
          title: "Goal",
          description: "Select goal",
          Children: GoalLlm,
          onSubmit: (values) => {
            const type = "customNode"
            id
              ? operations.onEditNode(values, nodes, edges, flowName, type)
              : operations.onAddNewNode(values, nodes, edges, flowName, type);
            nodeForm.form.reset({
              data: {},
            });
            stepper.setActiveStep(0);
            onClose();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
      ],
    },
    {
      label: "Tool",
      value: "tool_function",
      Icon: LiaToolsSolid,
      steps: [
        {
          title: "Basic",
          description: "Basic details",
          Children: BasicToolForm,
          onSubmit: (values) => {
            stepper.goToNext();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
        {
          title: "Tool",
          description: "Select tool",
          Children: BasicToolFunctionForm,
          onSubmit: (values) => {
            stepper.goToNext();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
        {
          title: "Data",
          description: "Data extraction",
          Children: ToolFunctionDataExtraction,
          onSubmit: (values) => {
            id
              ? operations.onEditNode(values, nodes, edges, flowName)
              : operations.onAddNewNode(values, nodes, edges, flowName);
            nodeForm.form.reset({
              data: {},
            });
            stepper.setActiveStep(0);
            onClose();
          },
          onBack: () => {
            stepper.goToPrevious();
          },
        },
      ],
    },
    {
      label: "Webhook",
      value: "webhook",
      Icon: MdOutlineWebhook,
      steps: [],
    },
  ];
  const findByNodeType = (nodeTypeOption) =>
    nodeTypeOption.value === data?.node_type;
  const selectedNode = nodeTypeOptions.find(findByNodeType);
  const nodeSteps = selectedNode?.steps || [];
  const steps = [
    {
      title: "Node Type",
      description: "Select Node Type",
      Children: NodeTypeSelectForm,
      onSubmit: () => {
        stepper.goToNext();
      },
    },
    ...nodeSteps,
  ];
  const stepper = useSteps({
    index: 0,
    count: steps.length,
  });

  return { stepper, steps, nodeTypeOptions };
}
