import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import ActionButtonFormFields from "./ActionButtonFormFields";
import ChatWidgetV2 from "./ChatWidgetV2";
import ColorPicker from "./ColorPicker";
import DisplayActionButtons from "./DisplayActionButtons";
import FilePicker from "./FilePicker";
import useChatWidgetAvatars from "./useChatWidgetAvatars";
import WidgetUserOpeningMessages from "./WidgetUserOpeningMessages";

export default function WidgetCustomizationV2({ v2Form, handleOnLoad }) {
  const { register, watch, control } = v2Form;
  const [message, setMessage] = useState("");
  const fieldArrayUserOpeningMessages = useFieldArray({
    control,
    name: "user_opening_messages",
  });
  const fieldArrayActionButtons = useFieldArray({
    control,
    name: "action_buttons",
  });
  const [actionButton, setActionButton] = useState({
    label: "",
    link: "",
    icon: "",
  });
  const onChangeActionButton = (e) => {
    setActionButton({
      ...actionButton,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };
  const { append } = fieldArrayUserOpeningMessages;
  const userOpeningMessages = watch("user_opening_messages") || [];
  const actionButtons = watch("action_buttons") || [];
  const onClickUserOpeningMessage = (index) =>
    fieldArrayUserOpeningMessages.remove(index);
  const handleActionButtonChange = ({ name, value }) =>
    setActionButton({
      ...actionButton,
      [name]: value,
    });
  const colorPickerConfigs = [
    { name: "header_text_color", label: "Header text color" },
    { name: "header_color", label: "Header" },
    { name: "bot_message_color", label: "Bot Message" },
    { name: "bot_text_message_color", label: "Bot text message color" },
    { name: "user_message_color", label: "User Message" },
    { name: "user_text_message_color", label: "User text message color" },
    { name: "bot_icon_color", label: "Bot Icon color" },
  ];
  const onClickSubmitActionButton = () => {
    if (!actionButton.icon || !actionButton.label || !actionButton.icon) return;
    fieldArrayActionButtons.append(actionButton);
    setActionButton({
      icon: "",
      label: "",
      link: "",
    });
  };
  const {
    onChangeConversationsBotIcon,
    onChangeBotIcon,
    removeBotIcon,
    removeConversationsBotIcon,
  } = useChatWidgetAvatars({
    widgetId: v2Form.getValues().id,
    cb: handleOnLoad,
  });

  return (
    <Grid gap={2} templateColumns={"1fr auto 1fr"}>
      <Stack spacing={2}>
        <Grid templateColumns={"1fr 1fr"} gap={2}>
          {colorPickerConfigs.map((colorPicker, index) => (
            <ColorPicker
              form={v2Form}
              key={index}
              label={colorPicker.label}
              name={colorPicker.name}
            />
          ))}
        </Grid>
        <Divider />
        <FormControl>
          <FormLabel>Display Name</FormLabel>
          <Input {...register("display_name")} />
        </FormControl>
        <Divider />
        <FormControl>
          <FormLabel>Intro message</FormLabel>
          <Input {...register("intro_message")} />
        </FormControl>
        <FormControl>
          <FormLabel>Sub-heading</FormLabel>
          <Input {...register("style_params.sub_heading")} />
        </FormControl>
        <Divider />
        <Text fontWeight={"bold"}>Chat bot CTA</Text>
        <FormControl>
          <FormLabel>Heading</FormLabel>
          <Input {...register("style_params.cta.heading")} />
        </FormControl>
        <FormControl>
          <FormLabel>Sub-heading</FormLabel>
          <Input {...register("style_params.cta.sub_heading")} />
        </FormControl>
        <FormControl>
          <FormLabel>Place holder text</FormLabel>
          <Input {...register("style_params.placeholder")} />
        </FormControl>
        <Divider />
        <Checkbox {...register("remove_branding")}>Remove branding</Checkbox>
        <Checkbox {...register("style_params.show_reset_button")}>
          Show reset button
        </Checkbox>
        <Divider />
        <Text fontWeight={"bold"}>Avatars</Text>
        <Grid w={"100%"} templateColumns={"1fr 1fr"} gap={2}>
          <FilePicker
            label={"Coversation Avatar"}
            handleChange={onChangeConversationsBotIcon}
            onClickDelete={removeConversationsBotIcon}
          />
          <FilePicker
            label={"Bot Avatar"}
            handleChange={onChangeBotIcon}
            onClickDelete={removeBotIcon}
          />
        </Grid>
        <Divider />
        <Stack spacing={3}>
          <FormControl>
            <FormLabel>Ice breakers</FormLabel>
            <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
              <Input
                flex={1}
                onChange={(e) => setMessage(e.currentTarget.value)}
                value={message}
                placeholder="Write your opening message here and press add"
              />
              <IconButton
                icon={<AddIcon />}
                colorScheme="blue"
                onClick={() => {
                  message && append(message);
                  setMessage("");
                }}
              />
            </Flex>
          </FormControl>
          <FormControl>
            <FormLabel>Ice breaker heading</FormLabel>
            <Input {...register("style_params.ice_breaker_heading")} />
          </FormControl>
          <WidgetUserOpeningMessages
            userOpeningMessages={userOpeningMessages}
            onClick={onClickUserOpeningMessage}
          />
          <Divider />
          <ActionButtonFormFields
            actionButton={actionButton}
            handleActionButtonChange={handleActionButtonChange}
            onChangeActionButton={onChangeActionButton}
            onClickSubmit={onClickSubmitActionButton}
          />
          <DisplayActionButtons
            actionButtons={actionButtons}
            onClickCloseIcon={fieldArrayActionButtons.remove}
          />
        </Stack>
      </Stack>
      <Divider orientation="vertical" />
      <Box>
        <Box margin={"auto"} width={"100%"} maxWidth={550}>
          <ChatWidgetV2 widgetProperties={watch()} />
        </Box>
      </Box>
    </Grid>
  );
}
