import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { timezones } from "../../utils/CountryList/CountryList";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";

const AddEditCampaign = (props) => {
  const {
    isAddEditCampaignOpen,
    onAddEditCampaignClose,
    selectedRow = null,
    setSelectedRow,
    getAllCampaign,
    getCampaignById,
    setTableData,
  } = props;

  const [widgets, setWidgets] = useState([]);
  const [widgetOptions, setWidgetOptions] = useState([]);
  const [timeZoneOptions, settimeZoneOptions] = useState([]);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [operation, setOperation] = useState({
    row: null,
    editing: false,
    index: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      campaignName: "",
      widget: "",
      startDate: "",
      timeZone: "Select Time Zone",
      startTime: "",
      endTime: "",
      selectedDays: [],
    },
  });

  const baseUrl = getHostDomain();
  const onSubmit = async (data) => {
    try {
      const selectedDaysIndex = data.selectedDays.map((day) =>
        days.indexOf(day)
      );
      const startDate = new Date(data.startDate).toISOString();
      const body = {
        name: data.campaignName,
        widget_id: data.widget.value,
        type: "outbound_call",
        attributes: {},
        status: "not_started",
        execution_weekdays: selectedDaysIndex,
        time_window_start: data.startTime,
        time_window_end: data.endTime,
        time_zone: data.timeZone.value,
        start_time: startDate.substring(0, startDate.length - 1),
        interval: 0,
      };
      const inputDateTime = new Date(
        `${body.start_time.slice(0, 10)}T${body.time_window_start}`
      );
      const now = new Date();
      const nowPlus30Minutes = new Date(now.getTime() + 30 * 60 * 1000);

      if (inputDateTime < nowPlus30Minutes) {
        throw new Error("Make sure start time is after 30 mins from now.");
      }
      const startTime = new Date(`${data.startDate}T${body.time_window_start}`);
      const endTime = new Date(`${data.startDate}T${body.time_window_end}`);
      if (startTime > endTime) {
        throw new Error("End Time must be greater than Start Time.");
      }
      const response = await fetchController(
        baseUrl +
          (operation.editing
            ? `/api/v1/campaign/update/${operation.row.id}`
            : "/api/v1/campaign/create"),
        operation.editing ? "PUT" : "POST",
        body
      );
      if (operation.editing) {
        const updatedRow = await getCampaignById(operation.row.id);
        setTableData((prevTableData) => {
          const newData = [...prevTableData];
          newData[operation.index] = {
            ...updatedRow,
            widget_name: watch("widget").label,
            start_time: startDate.split("T")[0],
            contacts: operation.row.contacts,
          };
          return newData;
        });
      } else getAllCampaign();
      onAddEditCampaignClose();
      toast.success(
        operation.editing ? "Campaign Updated !" : "Campaign Created !"
      );
    } catch (e) {
      console.log(e.message);
      toast.error(e.message ? e.message : "Something went wrong !");
    }
  };

  const getWidgetList = async () => {
    try {
      const urlHit = baseUrl + "/api/v1/widget/list";
      const widgetsData = await fetchController(urlHit, "GET");

      setWidgets(widgetsData.data.items);
      return widgetsData.data.items;
    } catch (e) {
      console.log(e);
    }
  };

  const createTimeZoneList = () => {
    const timezoneOptions = timezones.reduce((acc, timeZone) => {
      acc.push(...timeZone.utc);
      return acc;
    }, []);
    const zoneOptions = timezoneOptions.map((timezone) => ({
      value: timezone,
      label: `${timezone}`,
    }));
    settimeZoneOptions(zoneOptions);
  };

  const allowedWidgets = {
    twillio: true,
    plivo_call: true,
    twillio_call: true,
    telnyx_call: true,
  };

  useEffect(() => {
    (async () => {
      const options = await getWidgetList();
      options.forEach((widget) => {
        if (allowedWidgets[widget.widget_type]) {
          setWidgetOptions((prevData) => [
            ...prevData,
            { label: widget.name, value: widget.id },
          ]);
        }
      });
    })();
    createTimeZoneList();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      setOperation({
        row: selectedRow,
        editing: true,
        index: selectedRow.index,
      });
      const selectedDays = selectedRow.execution_weekdays.map(
        (day) => days[day]
      );
      setValue("campaignName", selectedRow.name);
      setValue("widget", {
        label: selectedRow.widget_name,
        value: selectedRow.widget_id,
      });
      setValue("startDate", selectedRow.start_time.split("T")[0]);
      setValue("timeZone", {
        label: selectedRow.time_zone,
        value: selectedRow.time_zone,
      });
      setValue("startTime", selectedRow.time_window_start.slice(0, 5));
      setValue("endTime", selectedRow.time_window_end.slice(0, 5));
      setValue("selectedDays", selectedDays);

      setSelectedRow(null);
    }
  }, [selectedRow]);

  return (
    <Modal
      isOpen={isAddEditCampaignOpen}
      onClose={onAddEditCampaignClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent maxW="688px">
        <ModalHeader style={{ fontWeight: 700, fontSize: "18px" }}>
          {operation.editing ? "Edit Campaign" : "Add Campaign"}
          <Flex style={{ fontWeight: 400, fontSize: "14px" }}>
            <Text>
              {operation.editing ? "Edit a" : "Create a new "} campaign in less
              than 5 minutes.
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton alignItems="center" />
        <Divider orientation="horizontal" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Box>
              <FormControl isInvalid={errors.campaignName}>
                <FormLabel>Campaign Name</FormLabel>
                <Input
                  type="text"
                  placeholder={"Enter Campaign Name"}
                  {...register("campaignName", {
                    required: "Campaign Name is required",
                  })}
                />
                {errors.campaignName && (
                  <FormErrorMessage>
                    {errors.campaignName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Flex gap={2} mt="2">
              <FormControl isInvalid={errors.widget}>
                <FormLabel>Widget</FormLabel>
                <Select
                  value={watch("widget")}
                  placeholder="Select Widget"
                  {...register("widget", {
                    required: "Widget is required",
                  })}
                  options={widgetOptions}
                  onChange={(selectedOption) => {
                    setValue("widget", selectedOption || null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />

                {errors.widget && (
                  <FormErrorMessage>{errors.widget.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={errors.name}>
                <FormLabelWithInfoTooltip
                  label={<div>Start Date </div>}
                  tip="Start time must be at least 30 mins after from now."
                />
                <InputGroup cursor="pointer">
                  <Input
                    type="date"
                    placeholder="Select Date"
                    {...register("startDate", {
                      required: "Start Date is required",
                    })}
                    sx={{
                      "&::-webkit-calendar-picker-indicator": {
                        position: "relative",
                        right: "99%",
                      },
                    }}
                  />
                </InputGroup>
                {errors.campaignName && (
                  <FormErrorMessage>
                    {errors.campaignName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex mt="2">
              <FormControl isInvalid={errors.timeZone}>
                <FormLabel>Time Zone</FormLabel>
                <Select
                  value={watch("timeZone")}
                  placeholder="Select Time Zone"
                  options={timeZoneOptions}
                  {...register("timeZone", {
                    required: "Time Zone is required",
                  })}
                  onChange={(selectedOption) => {
                    setValue("timeZone", selectedOption || null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {errors.timeZone && (
                  <FormErrorMessage>{errors.timeZone.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex gap={2} mt="2">
              <FormControl isInvalid={errors.startTime}>
                <FormLabelWithInfoTooltip
                  label={<div>Start Time </div>}
                  tip="Start time must be at least 30 mins after from now."
                />
                <Input
                  type="time"
                  placeholder={"Enter Start Time"}
                  {...register("startTime", {
                    required: "Start Time is required",
                  })}
                />

                {errors.startTime && (
                  <FormErrorMessage>
                    {errors.startTime.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={errors.endTime}>
                <FormLabel>End Time</FormLabel>
                <Input
                  type="time"
                  placeholder={"Enter End Time"}
                  {...register("endTime", {
                    required: "End Time is required",
                  })}
                />

                {errors.endTime && (
                  <FormErrorMessage>{errors.endTime.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Box mt="2">
              <FormLabel>Select Days</FormLabel>
              <FormControl isInvalid={errors.selectedDays}>
                <Flex>
                  <CheckboxGroup
                    colorScheme="blue"
                    {...register("selectedDays", {
                      required: "Select the Day",
                    })}
                    onChange={(e) => {
                      setValue("selectedDays", e);
                    }}
                    value={watch("selectedDays")}
                  >
                    <Stack spacing={[1, 5]} direction={["column", "row"]}>
                      {days.map((day) => (
                        <Checkbox value={day} key={day}>
                          {day}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </Flex>
                {errors.selectedDays && (
                  <FormErrorMessage>
                    {errors.selectedDays.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <Divider orientation="horizontal" />
          <ModalFooter width="100%">
            <Box>
              <Flex float="right" gap={4}>
                <Button
                  onClick={onAddEditCampaignClose}
                  variant="outline"
                  width="104px"
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#3B82F6",
                    color: "#FFFFFF",
                    width: "104px",
                  }}
                  type="submit"
                >
                  {!operation.editing ? "Add" : "Update"}
                </Button>
              </Flex>
            </Box>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddEditCampaign;
