import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Image,
  Stack,
  theme,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import React, { useContext, useState } from "react";
import { AiOutlineAim } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { LiaToolsSolid } from "react-icons/lia";
import {
  MdFormatListNumbered,
  MdOutlineCampaign,
  MdSchedule,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import { IoLibraryOutline } from "react-icons/io5";
import {
  liveAgents,
  navItemsData_1,
  navItemsData_5,
} from "../../utils/Data/data";
import { clipText } from "../../utils/utils";
import AuthContext from "../Auth/AuthContext";
import InstructionModal from "../Modals/InstructionModal";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import HelpMenu from "./HelpMenu";
import NavItem from "./NavItem";
import ScheduleDemo from "./ScheduleDemo";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { SiTheconversation } from "react-icons/si";
import { LuActivitySquare } from "react-icons/lu";
import { RiArrowDropDownLine } from "react-icons/ri";

function SidebarUserDetails(props) {
  const navigate = useNavigate();

  const openSettingsModal = () => {
    navigate("/settings/profile");
  };

  const agency = useContext(AgencyContext);
  const { state, loading: settingLoading } = useContext(SettingContext);

  const { buttonColorScheme, myDetails } = agency;

  const isScheduleDemoVisible =
    agency.loading || settingLoading
      ? false
      : myDetails?.agency_id
      ? false
      : state?.billing_plan_group === "202402_appsumo"
      ? false
      : true;

  const isDocsAndDiscordVisible = agency.loading
    ? false
    : myDetails?.agency_id
    ? myDetails?.branding?.socials?.discord ||
      myDetails?.branding?.socials?.docs
      ? true
      : false
    : true;

  const renderUserName = () => {
    const { navSize, userName, user } = props;

    if (navSize !== "small") {
      if (userName) {
        return userName;
      }
      if (user?.email) {
        return user.email;
      }
      return "Your Account";
    } else {
      return "";
    }
  };

  return (
    <Grid marginBlock={2} gap={2}>
      {isScheduleDemoVisible && <ScheduleDemo navSize={props.navSize} />}
      {isDocsAndDiscordVisible && (
        <HelpMenu agency={agency} navSize={props?.navSize} />
      )}

      <Stack direction="row" spacing={6} width={"100%"}>
        <Button
          width={"100%"}
          onClick={openSettingsModal}
          rightIcon={<IoMdSettings />}
          colorScheme={buttonColorScheme}
          variant="solid"
          display="flex"
          justifyContent="space-between"
          marginX={2}
          title={renderUserName().length > 10 && renderUserName()}
        >
          {clipText(renderUserName(), 10)}
        </Button>
      </Stack>
    </Grid>
  );
}

export default function SideBar() {
  const [navSize, changeNavSize] = useState("large");
  const navigate = useNavigate();

  const { isAuthenticated, authUser, logout } = useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleItemClick = (path) => {
    navigate(path);
  };
  const onClickLogout = () => {
    logout();
  };

  const userName =
    authUser?.first_name?.length > 15
      ? `${authUser?.first_name?.slice(0, 10)}...`
      : authUser?.first_name;

  function AIAgentsTab() {
    const location = useLocation();
    return (
      <Box borderRadius={"md"}>
        <Box onClick={() => handleItemClick("/ai-agents")}>
          <NavItem
            toolTip={
              "AI Agent helps you add your dataset, train your assistant, configure intents & launch widgets with ease."
            }
            navSize={navSize}
            icon={FaRobot}
            active={location.pathname.startsWith("/ai-agents")}
            title={"AI Agents"}
          />
        </Box>
        {[
          "/ai-agents",
          "/conversation-flow",
          "/forms",
          "/intents",
          "/tools",
          "/library",
        ].some((path) => location.pathname.startsWith(path)) ? (
          <ul
            style={{
              listStyle: "none",
              paddingLeft: 10,
            }}
          >
            {/* <li onClick={() => handleItemClick("/conversation-flow")}>
              <NavItem
                size="small"
                toolTip={"Configure conversation flow"}
                navSize={navSize}
                icon={SiTheconversation}
                active={location.pathname.startsWith("/conversation-flow")}
                title={"Conversation Flow"}
              />
            </li> */}
            <li onClick={() => handleItemClick("/forms")}>
              <NavItem
                size="small"
                toolTip={"Configure the forms"}
                navSize={navSize}
                icon={MdFormatListNumbered}
                active={location.pathname.startsWith("/forms")}
                title={"Forms"}
              />
            </li>
            <li onClick={() => handleItemClick("/intents")}>
              <NavItem
                size="small"
                toolTip={"Configure your intents here"}
                navSize={navSize}
                icon={AiOutlineAim}
                active={location.pathname.startsWith("/intents")}
                title={"Intents"}
              />
            </li>
            <li onClick={() => handleItemClick("/tools")}>
              <NavItem
                size="small"
                toolTip={"Configure different tools"}
                navSize={navSize}
                icon={LiaToolsSolid}
                active={location.pathname.startsWith("/tools")}
                title={"Tools"}
              />
            </li>
            <li onClick={() => handleItemClick("/library/prompts")}>
              <NavItem
                size="small"
                toolTip={"Configure libraries"}
                navSize={navSize}
                icon={IoLibraryOutline}
                active={location.pathname.startsWith("/library")}
                title={"Library"}
              />
            </li>
          </ul>
        ) : null}
      </Box>
    );
  }

  function EngagementsTTab() {
    const location = useLocation();
    return (
      <Box borderRadius={"md"}>
        <Box onClick={() => handleItemClick("/engagements")}>
          <NavItem
            toolTip="Engagements"
            navSize={navSize}
            icon={LuActivitySquare}
            active={location.pathname.startsWith("/engagements")}
            title={<>Engagements</>}
          />
        </Box>
        {["/engagements", "/campaigns"].some((path) =>
          location.pathname.startsWith(path)
        ) ? (
          <ul
            style={{
              listStyle: "none",
              paddingLeft: 10,
            }}
          >
            <li onClick={() => handleItemClick("/campaigns")}>
              <NavItem
                size="small"
                toolTip={"Campaigns"}
                navSize={navSize}
                icon={MdOutlineCampaign}
                active={location.pathname.startsWith("/campaigns")}
                title={"Campaigns"}
              />
            </li>
          </ul>
        ) : null}
      </Box>
    );
  }

  const agency = useContext(AgencyContext);
  const { agencyData, myDetails } = agency;
  const color_preset = agency?.data?.branding?.color_preset;
  const iconColor = useColorModeValue(
    color_preset || theme.colors.gray[500],
    "white"
  );
  const agencyLogoUrl = myDetails ? myDetails?.branding?.logo_url : null;
  const logo = !agency.loading ? (
    myDetails?.agency_id ? (
      agencyLogoUrl ? (
        <Image
          width="100px"
          src={
            agencyLogoUrl.includes(window.origin)
              ? agencyLogoUrl
              : `${window.location.origin}${agencyLogoUrl}`
          }
          alt={`${myDetails?.branding?.agency_name}-logo`}
        />
      ) : null
    ) : (
      <Image width="150px" src="/logo/indightoai.png" alt="Insighto.ai" />
    )
  ) : null;
  const userRoles = authUser?.roles || [];
  const isAgent = userRoles.includes("agent") && userRoles.length == 1;
  return (
    <div className="sidebar">
      <Flex
        h={"100svh"}
        boxShadow="md"
        borderRadius={navSize === "small" ? "8px" : "8px"}
        w={navSize === "small" ? "75px" : "240px"}
        flexDir="column"
        overflowY={"auto"}
        justifyContent="space-between"
      >
        <Grid>
          <Flex p={2} justifyContent={"center"} alignItems="center" gap={3}>
            <IconButton
              background="none"
              mt="5px"
              _hover={{ background: "none" }}
              icon={<FiMenu />}
              onClick={() => {
                if (navSize === "small") changeNavSize("large");
                else changeNavSize("small");
              }}
              color={iconColor}
            />
            <div
              style={{
                display: navSize === "small" ? "none" : "block",
                color: "#3182ce",
              }}
            >
              {logo}
            </div>
          </Flex>
          <div>
            {!isAgent ? (
              <>
                {navItemsData_1.map((item, index) => (
                  <div key={index} onClick={() => handleItemClick(item.path)}>
                    <NavItem
                      toolTip={item.toolTip}
                      navSize={navSize}
                      icon={item.icon}
                      active={item.path === window.location.pathname}
                      title={item.title}
                      description={item.description}
                    />
                  </div>
                ))}
                {navItemsData_5
                  .filter((item) =>
                    agency?.myDetails?.agency_id
                      ? item.title !== "Agency"
                      : true
                  )
                  .map((item, index) =>
                    item.title === "Engagements" ? (
                      <div>
                        <hr />
                        <EngagementsTTab />
                        <hr />
                      </div>
                    ) : (
                      <div
                        key={index}
                        onClick={() => handleItemClick(item.path)}
                      >
                        <NavItem
                          toolTip={item.toolTip}
                          navSize={navSize}
                          icon={item.icon}
                          active={window.location.pathname.includes(item.path)}
                          title={item.title}
                          description={item.description}
                        />
                      </div>
                    )
                  )}
                <hr />
                <AIAgentsTab />
                <hr />
              </>
            ) : null}
            {liveAgents.map((item, index) => (
              <div key={index} onClick={() => handleItemClick(item.path)}>
                <NavItem
                  toolTip={item.toolTip}
                  navSize={navSize}
                  icon={item.icon}
                  active={window.location.pathname.includes(item.path)}
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </Grid>
        {!isAgent ? (
          <>
            <InstructionModal onClose={onClose} isOpen={isOpen} />

            <SidebarUserDetails
              navSize={navSize}
              user={authUser}
              onClickLogout={onClickLogout}
              userName={userName}
            />
          </>
        ) : null}
      </Flex>
    </div>
  );
}
