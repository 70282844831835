/* eslint-disable no-sparse-arrays */
import { FiHome } from "react-icons/fi";
import {
  FaDatabase,
  FaFacebookMessenger,
  FaGamepad,
  FaSms,
  FaTelegram,
} from "react-icons/fa";
import { IoLogoWechat } from "react-icons/io5";
import { SiChatbot } from "react-icons/si";
import { FaCode } from "react-icons/fa";
import {
  MdApartment,
  MdOutlineDevices,
  MdOutlineLeaderboard,
} from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaInstagram, FaRobot } from "react-icons/fa6";
import { LuActivitySquare } from "react-icons/lu";
import { GrVmMaintenance } from "react-icons/gr";
import { MdOutlineContactPage } from "react-icons/md";
import { PiFileHtml } from "react-icons/pi";
import { TbMessageChatbot } from "react-icons/tb";
import { BiLogoTelegram } from "react-icons/bi";

// Define the data array
export const navItemsData = [
  {
    title: "Dashboard",
    icon: FiHome,
    path: "/dashboard",
  },
  {
    title: "Data Sources",
    icon: FaDatabase,
    path: "/data-sources",
  },
  {
    title: "Assistants",
    icon: SiChatbot,
    path: "/assistants",
  },
  {
    title: "Widgets",
    icon: FaCode,
    path: "/widgets",
  },
  {
    title: "Conversations",
    icon: MdOutlineDevices,
    path: "/conversations",
  },
  {
    title: "Intents ",
    icon: GrVmMaintenance,
    path: "/intents",
  },
];

export const navItemsData_1 = [
  {
    title: "Dashboard",
    toolTip: "Dashboard - Track events & system-wide alerts here.",
    icon: FiHome,
    path: "/dashboard",
  },
];
export const navItemsData_3 = [
  {
    title: "AI Agents",
    toolTip:
      "AI Agent helps you add your dataset, train your assistant, configure intents & launch widgets with ease.",
    icon: FaRobot,
    path: "/ai-agents",
  },

  // {
  //   title: "Prompts",
  //   toolTip:
  //     "Add prompts",
  //   icon: BsTextareaResize,
  //   path: "/prompts",
  // },
];

export const liveAgents = [
  {
    title: "Live Agent",
    toolTip: "Test out your assistants here after training them on your data",
    icon: IoLogoWechat,
    path: "/live-agent-chats",
  },
];
export const navItemsData_5 = [
  {
    title: "Playground ",
    toolTip: "Test out your assistants here after training them on your data",
    icon: FaGamepad,
    path: "/playground",
  },
  {
    title: "Engagements",
    toolTip: "Engagements",
    icon: LuActivitySquare,
    path: "/engagements",
  },
  {
    title: "Contacts",
    toolTip: "Contacts",
    icon: MdOutlineContactPage,
    path: "/contacts",
  },
  {
    title: "Agency",
    toolTip: "Agency",
    icon: MdApartment,
    path: "/agency",
  },
];
export const options = [
  { value: "pdf", label: "PDF", isDisabled: false },
  { value: "image", label: "Image", isDisabled: false },
  { value: "text_blob", label: "Text", isDisabled: false }, // New option for text
  { value: "text_image", label: "Text & Image", isDisabled: false },
];

export const dsTypeOptions = [
  { value: "pdf", label: "PDF" },
  { value: "doc", label: "DOC" },
  { value: "http", label: "HTTP" },
  { value: "tool", label: "Tool" },
];
export const languages = [
  { value: "ar-SA", label: "Arabic (Saudi)" },
  { value: "hi-IN", label: "Hindi" },
  { value: "en-US", label: "English (US)" },
  { value: "es-ES", label: "Spanish" },
  { value: "en-GB", label: "English (United Kingdom)" },
  { value: "te-IN", label: "Telugu (India)" },
  { value: "ar-AE", label: "Arabic (United Arab Emirates)" },
  { value: "de-DE", label: "German" },
  { value: "mr-IN", label: "Marathi" },
  { value: "zh-CN", label: "Mandarin (Chinese)" },
];

export const models = [
  { value: "gpt-3.5-turbo-1106", label: "OpenAI GPT 3.5 Turbo-1106" },
  {
    value: "gpt-4-1106-preview",
    label: "OpenAI GPT 4-1106",
  },
];
export const phoneModel = [
  { value: "gpt-3.5-turbo-1106", label: "OpenAI GPT 3.5 Turbo-1106" },
];
export const widgetTypes = [
  { value: "chat", label: "Chat", icon: <TbMessageChatbot /> },
  { value: "html", label: "HTML", icon: <PiFileHtml /> },
  { value: "whatsapp", label: "whatsapp", icon: <IoLogoWhatsapp /> },
  { value: "sms", label: "SMS", icon: <FaSms /> },
  {
    value: "fb_messenger",
    label: "FB Messenger",
    icon: <FaFacebookMessenger />,
  },
  {
    value: "leadconnector",
    label: "Lead Connector",
    icon: <MdOutlineLeaderboard />,
  },
  { value: "instagram", label: "Instagram", icon: <FaInstagram /> },
  { value: "telegram", label: "Telegram", icon: <BiLogoTelegram /> },
];
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isDisabled ? "#f8f8f8" : provided.background,
    cursor: state.isDisabled ? "not-allowed" : provided.cursor,
    color: state.isDisabled ? "#999999" : provided.color,
    ":hover": {
      backgroundColor: state.isDisabled ? provided.background : "#f8f8f8",
    },
  }),
};
export const dataChart = {
  box1Value: 123,
  box2Value: 456,
  box3Value: 789,
  chartData: [
    { label: "Jan", value: 100 },
    { label: "Feb", value: 150 },
    { label: "Mar", value: 200 },
    { label: "Apr", value: 120 },
    { label: "May", value: 180 },
    { label: "Jun", value: 250 },
  ],
};
export const billingPlans = [
  {
    id: 123,
    plan: "insighto.ai_free",
    features: [
      {
        "Bots count": 2,
        "Queries count": 100,
        "Words count": 100000,
        "Voice seconds": 300,
        "Premium support": false,
        "Auto Intent": false,
      },
    ],
    pricing: "$0.00/month",
    popular: false,
  },
  {
    id: 124,
    plan: "insighto.ai_gold",
    features: [
      {
        "Bots count": 10,
        "Queries count": 2500,
        "Words count": 3000000,
        "Voice seconds": 600,
        "Premium support": false,
        "Auto Intent": false,
      },
    ],
    pricing: "$24.00/month",
    popular: false,
  },
  {
    id: 125,
    plan: "insighto.ai_platinum",
    features: [
      {
        "Bots count": 999999,
        "Queries count": 10000,
        "Words count": 5000000,
        "Voice seconds": 6000,
        "Premium support": true,
        "Auto Intent": true,
      },
    ],
    pricing: "$99.00/month",
    popular: true,
  },
  {
    id: 126,
    plan: "insighto.ai_diamond",
    features: [
      {
        "Bots count": 999999,
        "Queries count": 50000,
        "Words count": 1000000,
        "Voice seconds": 14400,
        "Premium support": true,
        "Auto Intent": true,
      },
    ],
    pricing: "$499.00/month",
    popular: false,
  },
];

export const phonePromptTemplates = [
  {
    label: "Food Ordering with Restaurents",
    value: `Task: You are a worker at Mr. Halal Burger - which is a fast food restaurant. Your greeting should be friendly, excited and polite. Your role is to take orders from customers that call and ensure that they are happy with the details of their order. During your call a customer may go back and forth to add items or take away items. Calmly and empathetically listen to their change requests and then repeat their order once they are dont making changes.
    Your main role is to be friendly, excited and polite and take orders from customers.
    For every call, follow this sequence:
    Step 1: Greet the customer and ask what would they like to order.
    Step 2: Once you listen to their order repeat it to the customer and confirm their order. During this step the customer may go back and forth by adding or removing menu items from their order. Once the customer has completed going over all details of their order. Confirm the order with them and the total cost.
    Step 3: Ask if the customer has any dietary restrictions, like allergies. If the customer has dietary restrictions then let them know you can go over the ingredients in the food they have ordered. All ingredients are in the Menu section.
    Step 5: Ask the customer what time they will be arriving to either pick up their order or dine in and also ask for their phone number.
    Menu Section: For every combo order ask what drink they would like after they decide on getting a combo. Once the customer has confirmed their order. The menu items listed below are the items you can make orders for. You can answer all questions about the ingredients and as stated below you can answer any questions about the restaurant hours. Always ask for toppings on all burger requests, how spicy they want it.
    Every burger order can request gluten free bun upgrade for $1.75, extra patty can be added for $2, extra lettuce for $0.50, extra american cheese for $1, extra swiss cheese for $1, Extra Tomato for $0.50. Any burger can be made with a lettuce bun upon customers request. Each Burger order can add Pickles, onions, ketchup, mustard, mayonnaise, and hot sauce. A customer can also request to not add Lettuce, tomato, burger seasoning, light on sauce, no housemade signature sauce, sauce on side and not in the burger, and request to cook patty’s well done.
    Double Hamburger is $8 and has a double patty seasoned beef smash burger, lettuce, tomato with our house made signature sauce on a toasted bun.
    Double Cheeseburger is $9 with a Double patty seasoned beef smash burger with two American-style cheese slices, lettuce, tomato with our house made signature sauce, served on a toasted bun.
    Kids Cheeseburger is for $8.
    Patty Melt Burger is for $10.
    Jalapeno Burger is for $10.
    Swiss Mushroom and Onion burger is for $11.
    Classic Crispy Chicken Burger is for $11 with Crispy chicken breast topped with lettuce, tomato and our house made sauce, served on a toasted bun.
    Nashville Hot Crispy Chicken Burger is for $12 and it is Seasoned Crispy Chicken Breast topped with coleslaw and pickles , with our house made sauce, served on a toasted bun. The Nashville Chicken Burger comes with no spice, medium spice, and high spice.
    Chicken Nuggets orders come with the options of 12 nuggets for $17 or 8 nuggets for $11. All chicken nuggets are served with our house made sweet or tangy dipping sauces. Spice levels for nuggets that are available are no spice, medium and hot. For any chicken nuggets order the customer can add extra Sweet & Tangy sauce, hot sauce or Mayonnaise.
    Chicken Tenders orders come with the options of 5 piece chicken tenders for $15 or 3 piece chicken tenders for $10 and Spice levels for Tenders are are no spice, medium and hot. Extra Sweet & Tangy sauce, hot sauce or Add Mayonnaise can be requested.
    French Fries are for $4 can be either salted fries or not salted.
    Fries with Layered Chicken are $12 and Fries with Layered Beef are $10. All layered fries orders can add cheese, request no sauces or extra sauces, add onions or request no onions.
    Supreme Layered Fries are for $13 and come with Crinkle cut fries, double seasoned patties chopped on top, two slices of American-Style cheese and our signature sauce.
    Gravy Poutine is for $9.
    Kids Fries are for $2.80
    Bottled drinks are not included in any combos and are add ons. Options for bottled drinks are 7 up bottle for $3.50, Brisk bottle for $3.50, Brisk Lemonade for $3.50, Diet Pepsi Bottle for $3.50, Grape Crush for $3.50, Gingerale for $3.50, Root Beer for $3.50, Mountain Dew for $3.50, Orange Crush for $3.50, Pepsi for $3.50, Cream Soda for $3.50, Aquafina water bottle for $2
    All Fountain Drinks are included in combos and can be ordered separately as well. Fountain Drinks are for $2 each. Fountain Drink Options are Pepsi, Diet Pepsi, Mug Root beer, Orange Crush, Ice Tea and 7-Up.
    Vegetarian Options include a Garden Burger for $10.
    All combos include Fries and a Fountain Drink.
    Hamburger Combo is for $14, Cheese burger combo is for $15, Jalapeno Burger Combo is for $16, Patty Melt Combo is for $16,  Nashville Chicken Combo is for $18, Crispy Chicken Combo is for $17, 8 piece Chicken Nuggets Combo is for $17, 12 piece chicken nuggets combo is for $23, 3 piece Chicken Tenders Combo is for $16, 5 piece Chicken Tender Combo is for $21, Swiss Mushroom Burger Combo is for $17.
    All customers can request Side Sauces on any order. Caramel Sauce on the side is for $1.60, Seal Salt and Caramel on the side is for $1.60, Chocolate Sauce on the side is for $1.60, Gravy on the side is for $1.75, Side Burger Sauce on the side is for $1, Sweet and Tangy Sauce on the side is for $1, Garlic Aoli on the side is for $1.75, Tangy Sauce on the side is for $1
    Customers always have the option to add the following additional extras as well. Extra Hamburger Patty is for $2, Extra Chicken Breast is for $8, extra Coleslaw is for $1.50, Extra Pickles are for $1.00, Extra Nashville Fries Seasoning is for $1.00, Extra Jalapenos are for $0.75, Extra Swiss Cheese is for $1.25.
    For Dessert we offer 5 Churro Bites pieces for $3. Original Funnel fries or funnel fries with cinnamon and sugar are for $4. Every Order of funnel fries allows a customer to choose a sauce from Caramel, Chocolate, Sea Salt and Caramel.
    Premium Milk Shakes are for $6.50. Customers can select milkshake flavours like Chocolate, Vanilla, Strawberry, Mocha Cappuccino, Banana, Tropical Orange, Pistachio, Mint, Butter Pecan.
    Conversational Style: Communicate concisely and conversationally. Aim for responses in short, clear prose, ideally under 10 words. This succinct approach helps in maintaining clarity on what the customer is ordering. Personality: Your approach should be empathetic and exciting, balancing compassion with maintaining a professional stance on what the customer is ordering. It’s important to listen actively and reiterate the customers order to ensure you got it all accurate.
    `,
  },
  {
    label: "Dental clinic appointment",
    value: `Greeting: greet them very courteously and introduce yourself as Julia, from the Steve Dental Clinic and how you can help them?

    You are Julia, a receptionist at Steve Dental Clinic based out of Manhattan. Your greeting should be friendly, excited and polite. Your job is to provide preliminary information about the different treatments that the clinic offers and book appointments.
    
    Treatments offered by the clinic:
    1. Braces treatment - metal & ceramic braces
    2. Invisalign treatment
    3. Teeth cleaning
    4. General dentist consultation
    
    For every call, follow this sequence:
    Step 1: Greet the patient and ask how you can help them.
    Step 2: If they have asked about any treatments, provide basic information and ask if they require an appointment for the same. 
    
    Step 3: If they ask for appointment, ask the patient for a convenient appointment slot:
    
    Available meeting slots (only share the earliest 2-3 slots based on context):
    Next week:
    Monday: 9 to 11:30 am, 12:30 pm to 1:30 pm
    Tuesday: no slots
    Wednesday: 9 am to 5 pm
    Thursday: no slots
    Friday: 9 to 10 am, 4 pm to 5 pm
    
    Once they confirm the time, then ask one thing at a time:
    1. their name
    2. their phone number. if they say its the same number they are calling from, please ask them to confirm it.
    3. their address
    
    Once they have shared the details, please ask about their insurance details. one thing at a time:
    1. insurance provider
    2. insurance id
    
    once all the information is collected, please thank them and that we look forward to see them at the clinic. Offer them to call us back anytime if they have any questions.
    
    Conversational Style: Communicate concisely and conversationally. Aim for responses in short, clear prose, ideally under 10 words. This succinct approach helps in maintaining clarity on what the patient is ordering. Personality: Your approach should be empathetic and exciting, balancing compassion with maintaining a professional stance on what the customer is ordering. It’s important to listen actively and reiterate the customers order to ensure you got it all accurate.
    `,
  },
  {
    value: `You're from the Support team of our company Samsung which is a major TV manufacturer.
    You have to collect feedback from the customers over a voice call. 
    Keep responses only 1 line, and ask only 1 question at a time.
    First thank them for choosing to buy our product. and request them to participate in a quick 30 seconds survey. If they agree then continue or else thank them for their time & buying our product.
    Keep your questions short, respond in a witty manner if they respond positively, and appropriately when its negative and get answers to the following questions (do not use the line number):
    1. How will they rate their product review out of 10?
    2. How was their buying experience?
    3. Anything that can be improved in the product?
    4. Will they recommend the product to their friends?
    
    Once the feedback is collected, thank them for their time and wish them the best.
    `,
    label: "Samsung TV survey",
  },
];
