import { Box, Button, Flex, Heading, IconButton } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import AddEditCampaign from "./AddEditCampaign";
import CampaignTable from "./CampaignTable";
import useCampaign from "./useCampaign";

const Campaigns = () => {
  const {
    status,
    page,
    pages,
    paginationSize,
    campaignList,
    selectedRow,
    textColor,
    buttonColorScheme,
    isAddEditCampaignOpen,
    selectedCampaigns,
    tableData,
    setTableData,
    setPage,
    setSelectedCampaigns,
    setPages,
    setPaginationSize,
    setSelectedRow,
    onAddEditCampaignOpen,
    onAddEditCampaignClose,
    deleteCampaignById,
    getAllCampaign,
    updateCampaignById,
    getCampaignById,
    handleMultipleDelete,
  } = useCampaign();

  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
      >
        <Flex justifyContent={"space-between"}>
          <Box>
            <Heading color={textColor} fontSize="xl">
              Campaigns
            </Heading>
          </Box>
          <Flex gap={2}>
            <IconButton
              icon={<RiDeleteBin6Line />}
              bg="#FEF2F2"
              variant="ghost"
              color="#FCA5A5"
              _hover={{ bg: "#FEF2F2" }}
              isDisabled={selectedCampaigns.length > 1}
              onClick={(e) => {
                handleMultipleDelete();
              }}
            />
            <Button
              leftIcon={<FaPlus />}
              colorScheme="blue"
              onClick={onAddEditCampaignOpen}
            >
              Create Campaign
            </Button>
          </Flex>
        </Flex>

        {status === "pending" ? (
          <Loader />
        ) : (
          <CampaignTable
            onAddEditCampaignOpen={onAddEditCampaignOpen}
            paginationSize={paginationSize}
            setPaginationSize={setPaginationSize}
            tableData={tableData}
            setTableData={setTableData}
            buttonColorScheme={buttonColorScheme}
            setPage={setPage}
            page={page}
            pages={pages}
            setSelectedCampaigns={setSelectedCampaigns}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            updateCampaignById={updateCampaignById}
            getAllCampaign={getAllCampaign}
            getCampaignById={getCampaignById}
          />
        )}
      </Box>
      {isAddEditCampaignOpen && (
        <AddEditCampaign
          isAddEditCampaignOpen={isAddEditCampaignOpen}
          onAddEditCampaignClose={onAddEditCampaignClose}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          getAllCampaign={getAllCampaign}
          getCampaignById={getCampaignById}
          setTableData={setTableData}
        />
      )}
    </Layout>
  );
};

export default Campaigns;
